.filter-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 0.4rem;
    margin-top: 1.3rem;

}


filter-button  {
    margin: 0.5rem;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 2.3rem 5rem;
  }

  .col-1{
    background-color: #dae8ff;
    color: #fff
  }

  .col-2{
    background-color: #fff;
    color: #458efe
  }


  .col-3{
    background-color: #f85f60;
    color: #ffff
  }
  .switch-field {
	display: flex;
	margin-bottom: 36px;
    flex-wrap: wrap;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #a5dc86;
	box-shadow: none;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}


@media (min-width: 320px) and (max-width: 719px) {
  
    .filter-button-container {
        gap: 0;
    }
  }