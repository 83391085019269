/* FormComponent.css */

@media (max-width: 1024px) {
  .form-container {
    width: 100% !important; /* Set the width to 100% for screens equal to or less than 1024px */
    /* background-color: aqua; */
  }
}
.form-container {
  /* width: 40%; */
  /* margin: 0 auto; */
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* background-color: #f4f4f4; */
  padding-top: 8rem;
  margin: 10px;
  flex: 2 1 auto;
  width: 10%;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  font-size: 16px !important
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}

.ant-input {
  /* padding: 12px 11px; */
}
.paypal-buttons {
  /* position: absolute !important;
  top: 0; */
}

.ant-form-horizontal .ant-form-item-label {
  text-align: left !important;
}

.hover-icon-button {
  border: 1px solid rgb(227, 228, 229);
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 10px;
}

.hover-icon-button:hover{
  background-color: green !important;
  border: 1px solid green !important;
  color:green !important;
}

.hover-icon-button:hover .icon {
  color: white; /* Change the color to your desired hover color */
}

.icon {
  font-size: 24px;
  transition: color 0.3s ease-in-out;
  color: #000000; /* Default color of the icon */
}
