.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;

    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #D3D3D3 transparent transparent transparent;
  }