
  
  .legend-card-grid {
    
    /* position:absolute;
    top: 3%;
    left:1%;
    z-index:999; */
  }
 
  
  
  .legend-card-wrap {
    float:left;
    width:100%;
    padding:5px;
    background: #ffff;
    padding: 0.3rem;
  }
  @media (min-width: 320px) and (max-width: 719px) {
    .legend-card-wrap {
      width: 100%;
    font-size: 14px;
    }
  }
  @media (min-width: 720px) {
    .legend-card-wrap {
      width:100%;
    }
  }
  
  
  .legend-card {
    background-color:white;
    border-radius:5px;
   padding-bottom: 0.2rem;
    border:1px solid #ccc;
    border-bottom:2px solid #ccc;
  }
  .legend-card > div {
    padding:0 1em;
  }

  .circle-btn {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    display:inline-block;
    line-height: 1.42857;
  }

  .selected-circle::after {
    content: "";
    display: inline-block;
    margin-bottom: 3px;
    color: green;
    background: green content-box;
    border: 2px solid green;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  
  .container {
    display: flex;
    align-items: baseline;
    /* flex-direction: column; */
    /* align-content: space-between; */
    flex-wrap: wrap;
    gap: 1rem;

}

.label {
    padding-left: 0.3rem
}
  