@media (max-width: 1024px) {
  .order-card {
    width: 100% !important; /* Set the width to 100% for screens equal to or less than 1024px */
    /* background-color: aqua; */
  }
}

.parent-order {
  /* width: 40%; */
  flex: 0.5 1 auto;
  padding-top: 11.2rem;
}

.order-card {
  width: 450px; /* Set the width to 100% for screens equal to or less than 1024px */
}


p {
  font-size: 12px !important;
}


.table-container {
  height: 150px;
  margin-left: 10px;
  margin-right: 10px;
  overflow-y: auto;
  margin-bottom: 10px;
  /* border: 1px solid rgb(227, 228, 229); */
}

.ant-table-tbody > tr > td {
  border: none;
}

/* Remove border from table cells */
.ant-table-cell {
  border: none;
}
.cost{
  float: right;
  font-weight: 800;
}