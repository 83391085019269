.checkout-parent{
    /* width: 50%; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}


.hEyZIb {
    background-color: green
    
  }
  
  .jPQkWg {
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 2.43;
    width: 100%;
    padding: 0px 12px;
    min-width: 60px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    color: rgb(255, 255, 255);
    /* background-color: rgb(2, 108, 223); */
    border: 1px solid transparent;
    transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }

  .disabled{
    background-color: gray !important;
  }
  
  .cRkVpC {
    font-size: 16px;
  }
  
  .place-order {
    margin-top: 0px;
    margin-bottom: 20px;
  }