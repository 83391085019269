.parent {
    /* display: flex; */
    
}

.nav-header {
    background-color: #ffff;
    display: flex;
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
    align-content: space-between;
    flex-wrap: wrap;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
}

@media (min-width: 320px) and (max-width: 719px){
    .wrapper {
        gap: 0;
    }
}